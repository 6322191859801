import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Webflow is hard to beat as a solution for the marketing site for your
application. Netlify is a great CI/CD & hosting option for your front-end
application. This article briefly shows how to configure Netlify to send
particular routes to Webflow so that you can selectively serve pages that are
designed and hosted on Webflow.`}</p>
    <p>{`We want some URLs served directly by Netlify for our application and other URLs
served by Webflow for marketing.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`merrickchristensen.com`}</code>{`
`}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{`👈
Hosted on Webflow`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`merrickchristensen.com/app`}</code>{` `}{` `}{` `}{`👈 Hosted on Netlify`}</p>
    <h2>{`1. Point Your Desired Domain to Netlify`}</h2>
    <p>{`Set your custom domain up with Netlify, follow
`}<a parentName="p" {...{
        "href": "https://docs.netlify.com/domains-https/custom-domains/"
      }}>{`Netlify's official custom domain instructions`}</a>{`.`}</p>
    <h2>{`2. Setup a Subdomain For Webflow`}</h2>
    <p>{`Connect a custom subdomain to Webflow, follow
`}<a parentName="p" {...{
        "href": "https://university.webflow.com/lesson/connect-a-subdomain"
      }}>{`Webflow's official subdomain setup instructions`}</a>{`,
as part of this setup you'll be asked to create a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CNAME`}</code>{` record that points to
Webflow. In my case, I use Netlify as my name servers, so I setup a
`}<a parentName="p" {...{
        "href": "https://docs.netlify.com/domains-https/netlify-dns/dns-records/#add-a-new-record"
      }}>{`CNAME record in Netlify`}</a>{`.
Don't worry, your subdomain won't be what your customers see, so the actual
subdomain doesn't matter much.`}</p>
    <p><img parentName="p" {...{
        "src": "/assets/images/articles/using-webflow-and-netlify/netlify-domain.png",
        "alt": "Netlify CNAME Settings Example"
      }}></img></p>
    <blockquote>
      <p parentName="blockquote">{`Netlify Subdomain Settings`}</p>
    </blockquote>
    <p><img parentName="p" {...{
        "src": "/assets/images/articles/using-webflow-and-netlify/webflow-domain.png",
        "alt": "Webflow Subdomain Added to Project"
      }}></img></p>
    <p>{`If you go to the subdomain directly it should be publicly available and served
by Webflow. All good, let's look at sharing a domain between the two.`}</p>
    <h2>{`3. Send Traffic For Desired Pages To Webflow Using Netlify Proxies`}</h2>
    <p>{`Using Netlify `}<a parentName="p" {...{
        "href": "https://docs.netlify.com/routing/redirects/"
      }}>{`redirects`}</a>{` &
specifically,
`}<a parentName="p" {...{
        "href": "https://docs.netlify.com/routing/redirects/rewrites-proxies/#proxy-to-another-service"
      }}>{`proxies`}</a>{`
we can selectively proxy certain URL paths to our Webflow site. The key to
making Netlify proxy requests is to use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`200`}</code>{` status code. The syntax for a
proxy directive, which would go in your Netlify `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`_redirects`}</code>{` file, is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`<path> <full-url-to-proxy> <status-code>`}</code></pre></div>
    <p>{`So to send the traffic of our homepage to our Webflow site we can add the
following directive.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`/ https://marketing-site.merrickchristensen.com 200`}</code></pre></div>
    <p>{`This will send all traffic to the root page from the domain hosted by Netlify,
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`merrickchristensen.com`}</code>{` to our Webflow site.`}</p>
    <p>{`We can route other pages to, we can also take advantage of Netlify `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`:splat`}</code>{`
syntax to support Webflow CMS URLs.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`/  https://marketing-site.merrickchristensen.com/  200
/contact-us  https://marketing-site.merrickchristensen.com/contact-us 200
/blog/:splat https://marketing-site.merrickchristensen.com/blog/:splat  200
        ^                                                          ^
        |                                                          |
        :splat groups that part of the URL and and be used in the redirect
        this is primarily useful for supporting CMS paths in Webflow.`}</code></pre></div>
    <p>{`All of your other URLs will be handled by Netlify by default.`}</p>
    <h2>{`Trade Offs`}</h2>
    <p>{`In addition to building really fast websites, Webflow has really fast hosting.
Webflow will serve pages using a CDN which means that your customers will get
your site delivered to them by a server near them which means they'll see your
site faster. Unfortunately, the proxy setup goes through Netlify first which
means we are making one additional hop from Netlify to Webflow so there will be
some additional latency. To be honest, for most usecases this latency is really
minimal and negligible. In my minimal testing, proxying through Netlify added
roughly 50-100 milliseconds to the request.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      